export default function () {
    let GTMCenter = 'CEIP';
    const route = useRoute();
    if (route.path.startsWith('/china')) {
        GTMCenter = 'Carnegie China';
    } else if (route.path.startsWith('/europe')) {
        GTMCenter = 'Carnegie Europe';
    } else if (route.path.startsWith('/russia-eurasia')) {
        GTMCenter = 'Carnegie Russia Eurasia';
    } else if (route.path.startsWith('/india')) {
        GTMCenter = 'Carnegie India';
    } else if (route.path.startsWith('/middle-east')) {
        GTMCenter = 'Carnegie Middle East Center';
    }
    return { GTMCenter };
}
